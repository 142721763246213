import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IGetNext } from '../utils/types';
import { getNextResponse, encryptAndUploadToS3 } from '../utils/apiServices';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Prompt.css';
import 'react-puzzle-captcha/dist/react-puzzle-captcha.css';
import { useConnectWallet } from '../hooks/wallet/walletHooks';
import { useWalletStore } from '../hooks/wallet/walletStore';
import { signMessage } from '../hooks/wallet/walletService';
import { ethers, EventLog } from 'ethers';
import * as eccrypto from "@toruslabs/eccrypto";
import Tour from 'reactour'
import { TOUR_STEPS, WALLET_SIGN_FIXED_MESSAGE, DLP_UPLOAD_STEP_DESCRIPTIONS, UPLOAD_DATASET_STEPS } from '../utils/constants';
import * as Sentry from "@sentry/react";

import DataLiquidityPoolABI from '../contracts/DataLiquidityPoolImplementation.json'
import TeePoolImplementationABI from '../contracts/TeePoolImplementation.json'
import DataRegistryImplementationABI from '../contracts/DataRegistryImplementation.json'
import { DataLiquidityPoolImplementation } from '../utils/contracts/dlp';
import { DataRegistryImplementation } from '../utils/contracts/dataRegistry';
import { TeePoolImplementation } from '../utils/contracts/teePool';
import PromptSubmitGroup from '../components/prompt/PromptSubmitGroup';
import CaptchaModal from '../components/common/CaptchaModal';
import PromptLabel from '../components/prompt/PromptLabel';
import PromptResponseView from '../components/prompt/PromptResponseView';
import LogoCyanImg from '../assets/images/logo-cyan-circle.svg'
import LoadingImg from '../assets/images/loading.svg'
import ScrollTopButton from '../components/common/ScrollTopButton';
import UseCapsuleModal from '../components/common/CapsuleModal';
import { capsuleClient } from "../utils/capsule";
import ProgressBar from '../components/common/ProgressBar';
import { fileJobIds, getTeeDetails } from '../utils/dlpServices';
import { useNetworkStore } from '../hooks/network/networkStore';

const tourStyles = {
  buttonSkip: {
    padding: '8px 16px',
    background: '#666',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginRight: '10px',
  },
  buttonNext: {
    padding: '8px 16px',
    background: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  }
};

const Prompt: React.FC = () => {
  const [getNextData, setGetNextData] = useState<IGetNext | null>(null);
  const [nextData, setNextData] = useState<IGetNext | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [lastClickTime, setLastClickTime] = useState<number | null>(null);
  const [isCaptchaModalOpen, setIsCaptchaModalOpen] = useState(false);
  const { disconnect } = useConnectWallet();
  const walletAddress = useWalletStore((state) => state.walletAddress);
  const [isShowScrollTopBtn, setIsShowScrollTopBtn] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [isDataSetSizeLimit, setIsDataSetSizeLimit] = useState(false);
  const [isCapsuleModalOpen, setIsCapsuleModalOpen] = useState(false);
  const [progress, setProgress] = useState(UPLOAD_DATASET_STEPS.NOT_STARTED);

  const contractAddress = useNetworkStore((state) => state.smartContracts.dlp);
  const dataRegistryContractAddress = useNetworkStore((state) => state.smartContracts.dataRegistry);
  const teePoolContractAddress = useNetworkStore((state) => state.smartContracts.teePool);

  useEffect(() => {
    const handleResize = () => {
      setIsShowScrollTopBtn(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const { fixed_iv, fixed_ephemeral_key } = useMemo(() => {
    return {
      fixed_iv: Buffer.from(crypto.getRandomValues(new Uint8Array(16))),
      fixed_ephemeral_key: Buffer.from(crypto.getRandomValues(new Uint8Array(32)))
    };
  }, []);

  const encryptWithWalletPublicKey = useCallback(async (data: string, publicKey: string): Promise<string> => {
    const publicKeyBytes = Buffer.from(publicKey.startsWith("0x") ? publicKey.slice(2) : publicKey, "hex");
    const uncompressedKey = publicKeyBytes.length === 64 ? Buffer.concat([Buffer.from([4]), publicKeyBytes]) : publicKeyBytes;

    const encryptedBuffer = await eccrypto.encrypt(uncompressedKey, Buffer.from(data), {
      iv: fixed_iv,
      ephemPrivateKey: fixed_ephemeral_key,
    });
    const encryptedHex = Buffer.concat([encryptedBuffer.iv, encryptedBuffer.ephemPublicKey, encryptedBuffer.ciphertext, encryptedBuffer.mac]).toString("hex");
    return encryptedHex;
  },
    [fixed_iv, fixed_ephemeral_key]
  );

  const uploadDataSetToDlp = async () => {
    try {
      let existingData;
      const existingDataString = localStorage.getItem("Prompt") || "";
      if (existingDataString) {
        existingData = JSON.parse(existingDataString);
      }

      if (!existingData) {
        toast.error("No data to upload", {
          position: "top-right",
          autoClose: 2500,
          style: { marginTop: "50px" }
        });
        return;
      }

      if (!walletAddress) {
        toast.error("Please connect your wallet first", {
          position: "top-right",
          autoClose: 2500,
          style: { marginTop: "50px" }
        });
        return;
      }
      Sentry.captureMessage(`DataSet upload started! Wallet address: ${walletAddress}`);
      setProgress(UPLOAD_DATASET_STEPS.GET_SIGNATURE);
      const signature = await signMessage(walletAddress, WALLET_SIGN_FIXED_MESSAGE);
      console.log("=== signature===>", signature);

      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();

      const dlpContract = new ethers.Contract(contractAddress, DataLiquidityPoolABI.abi, signer) as unknown as DataLiquidityPoolImplementation;
      const dataRegistryContract = new ethers.Contract(dataRegistryContractAddress, DataRegistryImplementationABI.abi, signer) as unknown as DataRegistryImplementation;
      const teePoolContract = new ethers.Contract(teePoolContractAddress, TeePoolImplementationABI.abi, signer) as unknown as TeePoolImplementation;

      setProgress(UPLOAD_DATASET_STEPS.GET_PUBLIC_KEY);
      console.log("=== contractAddress ===>", contractAddress)
      console.log("=== dataRegistryContractAddress ===>", dataRegistryContractAddress)
      console.log("=== teePoolContractAddress ===>", teePoolContractAddress)

      const publicKey = await dlpContract.publicKey();
      console.log("DLP public Key:", publicKey);

      setProgress(UPLOAD_DATASET_STEPS.ENCRYPT_KEY);
      const encryptedKey = await encryptWithWalletPublicKey(signature, publicKey);
      console.log(`encryptedKey: '${encryptedKey}'`);

      setProgress(UPLOAD_DATASET_STEPS.UPLOAD_DATA);
      const sharedUrl = await encryptAndUploadToS3(existingData, signature);
      console.log("sharedUrl:", sharedUrl);

      setProgress(UPLOAD_DATASET_STEPS.ADD_FILE_TO_DATA_REGISTRY);
      const permissions = [
        {
          account: contractAddress,
          key: encryptedKey
        }
      ];
      let uploadedFileId: number | null = null;

      const tx = await dataRegistryContract.addFileWithPermissions(
        sharedUrl as any,
        walletAddress as any,
        permissions as any
      );
      const receipt = await tx.wait();
      console.log("File added with permissions, transaction receipt:", receipt?.hash);
      if (receipt && receipt.logs.length > 0) {
        const eventLog = receipt.logs[0] as EventLog;

        // Check if the event is the one we're expecting
        if (eventLog.topics[0] === ethers.id("FileAdded(uint256,address,string)")) {
          const decodedLog = dataRegistryContract.interface.parseLog({
            topics: eventLog.topics,
            data: eventLog.data,
          });

          if (decodedLog && decodedLog.args) {
            uploadedFileId = Number(decodedLog.args[0]);
            const owner = decodedLog.args[1];
            const url = decodedLog.args[2];

            console.log("File ID:", uploadedFileId);
            console.log("Owner:", owner);
            console.log("URL:", url);
          }
        }
      }

      setProgress(UPLOAD_DATASET_STEPS.GET_TEE_DETAILS);
      console.log(`File uploaded with ID: ${uploadedFileId}`);
      const teeFee = await teePoolContract.teeFee();
      const teeFeeInVana = ethers.formatUnits(teeFee, 18);
      console.log(`TEE fee fetched: ${teeFeeInVana} VANA for running the contribution proof on the TEE`);

      setProgress(UPLOAD_DATASET_STEPS.REQUEST_CONTRIBUTION_PROOF);
      console.log(`Requesting contribution proof from TEE for FileID: ${uploadedFileId}...`);
      const contributionProofTx = await teePoolContract.requestContributionProof(
        uploadedFileId as any,
        { value: teeFee } as any
      );
      const contributionProofReceipt = await contributionProofTx.wait();
      console.log(`Contribution proof requested. Transaction hash: ${contributionProofReceipt?.hash}`);

      setProgress(UPLOAD_DATASET_STEPS.GET_JOB_ID);
      const jobIds = await fileJobIds(teePoolContract, uploadedFileId as number);
      const latestJobId = jobIds[jobIds.length - 1] as number;
      console.log(`Latest JobID for FileID ${uploadedFileId}: ${latestJobId}`);

      setProgress(UPLOAD_DATASET_STEPS.GET_JOB_DETAILS);
      const jobDetails = await getTeeDetails(teePoolContract, latestJobId);
      console.log(`Job details retrieved for JobID ${latestJobId}`);

      console.log("Job Details:", jobDetails);
      console.log(
        "TODO: Implement query to TEE Attestation URL:",
        jobDetails.teeUrl
      );
      console.log(`Preparing contribution proof request for TEE`);

      const dlpProofInstruction = await dlpContract.proofInstruction();
      console.log("=== dlpProofInstruction ===>", dlpProofInstruction)

      // Prepare the request body
      const requestBody: any = {
        job_id: latestJobId,
        file_id: uploadedFileId,
        nonce: "1234",
        encryption_seed: WALLET_SIGN_FIXED_MESSAGE,
        proof_url: dlpProofInstruction,
        secrets: {
          AWS_ACCESS_KEY_ID: `9dd64b939200be61d026a1fb933e837fdb904e1b036de5334056c73d339a946ce154a97fcfb227fffad321ceb6e4b7050292ef24ea9138495c58d96f00662307ddee33ded9649ee5805cde5be9c42f2436a96c0fddc25e473ce65ee18e0efb502468e3f18ed8e60f231d8ff99186a0c01aee8feb53f65950277c199eb1d99bfa42c8d7180627ff068af37583d736b1fe5240fd1b01138934d91d58ff9d9b4383a04d245621b1c45d205d1a751ea647b1e4475c345d170dbf5107f790cc84da829b8f766de76475374cd14b76430119bb870ca0704d464c012cb30d7d9e8405d3507f500723c95a759c62dec8c6ce7b5c4ea7cfc5582038c5df51ed155f6fc202
20a195ebdaf528ad9e378f6a7f15b5d0f420c1fd741e6f3b9ef05b08c8bc6f7264b123b610a9c89583d9ede96e6bd54f1b1051b19c4e92eff8fa1a50933e4de2ac33d6e954c5c4052ee535b57ab9c12156805cc1575b7f762cd2d25a09bfae10f109988c57252e7af3c1a94cbd90591649a834fc29ba7f95ac2a555ec99d0880`,
          AWS_SECRET_ACCESS_KEY: `ba6055402678469a8f44aaa8c11aa74815a868e5a5ef8be916cdf666ec0f4dd00f0293b2c85b4dc530a1c5b3693ca3a33e9d260d608b81f08638de2642cbb6c9d2a478ca9c7405961bfa08f1fe9ca870599ea218984095ca8a931a4b105c00c2bb3655f3d68dd63dbf83b2dd2beb11c2a2db2986198946ca169f0535fe4e6134f35a9cb818f15d5ae782e771d4810725e536dee05ecc962de8ed5128fea3de58d8b64099b010ddcc9babae56197874ea75f49170589544d0ee98d751aadebdd3267b70dcae7912e2b9feddb44abeefdbc0777835dd10c4a141d194ef43382180a15f3035b93c326c211fc05f3a9068b787bd2fe2f6944843c1b16788068ef6d0
93d31b0bff470ec8d1db08d59511a5edba9165a77cc1102dfe754033ec860507612fdc6b2314c9008c1b0d5233674e3e9479f54e7b7bf0bccfe1012f0d90d5c03614761cb7e8217fff8d0e1575d8ac4db3b22e3c0e3fa7d22926c04e10f08979382222b0946baf81ca98d1468997b5a5486b0e29b860664aa18c79042d82e37c`
        },
        validate_permissions: [
          {
            address: contractAddress,
            public_key: publicKey,
            iv: fixed_iv.toString('hex'),
            ephemeral_key: fixed_ephemeral_key.toString('hex'),
          }
        ]
      };

      console.log("=== jobDetails.teePublicKey ===>", jobDetails.teePublicKey)

      setProgress(UPLOAD_DATASET_STEPS.RUN_PROOF);
      // If TEE public key is available, encrypt the encryption key
      if (jobDetails.teePublicKey) {
        try {
          console.log(`=== Encrypting encryption key with TEE public key ===`);
          const encryptedKey = await encryptWithWalletPublicKey(signature, jobDetails.teePublicKey);
          requestBody.encrypted_encryption_key = encryptedKey;
          console.log(`Encryption key encrypted successfully`);
        } catch (error) {
          console.error("Error encrypting encryption key:", error);
          console.log(`Warning: Failed to encrypt encryption key, falling back to direct encryption key`);
          requestBody.encryption_key = signature;
        }
      } else {
        console.log(`TEE public key not available, using direct encryption key`);
        requestBody.encryption_key = signature;
      }

      console.log(`Sending contribution proof request to TEE: ${jobDetails.teeUrl}/RunProof`);
      console.log("Request Body:", requestBody);
      const contributionProofResponse = await fetch(
        `${jobDetails.teeUrl}/RunProof`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!contributionProofResponse.ok) {
        const errorData = await contributionProofResponse.json();
        throw new Error(`TEE request failed: ${JSON.stringify(errorData)}`);
      }

      const contributionProofData = await contributionProofResponse.json();
      console.log("Contribution proof response:", contributionProofData);
      console.log(`Contribution proof response received from TEE. Requesting a reward...`);

      setProgress(UPLOAD_DATASET_STEPS.CLAIM_REWARD);
      const requestClaimTx = await dlpContract.requestReward(uploadedFileId as any, 1 as any);
      const claimRewardReceipt = await requestClaimTx.wait();

      // Get reward amount
      if (claimRewardReceipt && claimRewardReceipt.logs.length > 0) {
        const eventLog = claimRewardReceipt.logs[0] as EventLog;

        if (eventLog.topics[0] === ethers.id("RewardRequested(address,uint256,uint256,uint256)")) {
          const decodedLog = dlpContract.interface.parseLog({
            topics: eventLog.topics,
            data: eventLog.data,
          });
          if (decodedLog && decodedLog.args) {
            const rewardAmount = ethers.formatUnits(decodedLog.args[3], 18);
            console.log("Reward amount:", rewardAmount, decodedLog.args[3]);
            toast.success(`Reward received successfully! ${rewardAmount} VANA`, {
              position: "top-right",
              autoClose: 2500,
              style: { marginTop: "50px" }
            });
          }
        }
      }
      console.log("Reward received successfully", claimRewardReceipt);
      clearDataSet();
      Sentry.captureMessage(`Reward received successfully: ${claimRewardReceipt} VANA! Wallet address: ${walletAddress}`);
    } catch (error: any) {
      Sentry.captureException(error);
      console.error("Error in file upload process:", error)
      toast.error(`Exception occur while ${DLP_UPLOAD_STEP_DESCRIPTIONS[progress - 1]}`, {
        position: "top-right",
        autoClose: 2500,
        style: { marginTop: "50px" }
      });
    } finally {
      setProgress(UPLOAD_DATASET_STEPS.NOT_STARTED);
    }
  }

  const getNextRes = async () => {
    setIsLoading(true);

    let response;
    if (nextData) {
      response = nextData;
      setNextData(null);
    } else {
      response = await getNextResponse();
    }

    setGetNextData(response);
    setIsLoading(false);

    const nextResponse = await getNextResponse();
    if (nextResponse) setNextData(nextResponse);
  }

  const handleSaveJsonData = (newData: IGetNext | null) => {
    if (newData) {
      let existingData;
      const existingDataString = localStorage.getItem("Prompt") || "";
      if (existingDataString) existingData = JSON.parse(existingDataString);
      if (existingData) {
        existingData.push(newData);
        localStorage.setItem("Prompt", JSON.stringify(existingData));
      }
      else {
        localStorage.setItem("Prompt", JSON.stringify([newData]));
      }
    }
  }

  const clearDataSet = () => {
    localStorage.setItem("Prompt", "");
    setCount(0);
    localStorage.setItem("PromptCalledCount", JSON.stringify(1));
  }

  const handleGetUnclaimedDataSet = () => {
    let existingData;
    const existingDataString = localStorage.getItem("Prompt") || "";
    if (existingDataString) existingData = JSON.parse(existingDataString);
    if (existingData) setCount(existingData.length);
  }

  const handleSaveCurrentPromtCalledCount = () => {
    let existingPromptCalledCount;
    const existingPromptCalledCountString = localStorage.getItem("PromptCalledCount") || "";
    if (existingPromptCalledCountString) existingPromptCalledCount = JSON.parse(existingPromptCalledCountString);
    if (existingPromptCalledCount) {
      localStorage.setItem("PromptCalledCount", JSON.stringify(Number(existingPromptCalledCount) + 1));
    }
    else {
      localStorage.setItem("PromptCalledCount", JSON.stringify(1));
    }

    return Number(existingPromptCalledCount) + 1 >= 10;
  }

  const handleCheckFirstTime = () => {
    let existingFirstTime;
    const existingFirstTimeString = localStorage.getItem("CheckFirstTime") || "";
    if (existingFirstTimeString) existingFirstTime = JSON.parse(existingFirstTimeString);
    if (existingFirstTime) return false;
    else {
      localStorage.setItem("CheckFirstTime", JSON.stringify(1));
      return true;
    }
  }

  const handleGetCountSaveJsonData = () => {
    let existingData;
    const existingDataString = localStorage.getItem("Prompt") || "";
    if (existingDataString) existingData = JSON.parse(existingDataString);
    if (existingData) {
      if (existingData.length >= 99) return true;
      else return false;
    }
    else return false;
  }

  useEffect(() => {
    if (isDataSetSizeLimit) {
      toast.error("Dataset size is greater than 100, please submit to continue.", {
        position: "top-right",
        autoClose: 2500,
        style: { marginTop: "50px" }
      });
    }
  }, [isDataSetSizeLimit])

  useEffect(() => {
    getNextRes();
    if (handleSaveCurrentPromtCalledCount()) setIsCaptchaModalOpen(true);
    setIsDataSetSizeLimit(handleGetCountSaveJsonData());
    // Check if MetaMask is installed
    if (typeof window.ethereum !== 'undefined') {

      // Check if wallet is connected
      window.ethereum
        .request({ method: 'eth_accounts' })
        .then((accounts) => {
          if (!(accounts && accounts.length > 0)) {
            disconnect();
            capsuleClient.logout();
          }
        });

      // Listen for account changes
      window.ethereum.on('accountsChanged', (accounts) => {
        if (accounts.length <= 0) {
          disconnect();
          capsuleClient.logout();
        }
      });
    }

    setIsTourOpen(handleCheckFirstTime());
    handleGetUnclaimedDataSet();
  }, [])

  const handleClickOption = (method: string) => {
    if (handleSaveCurrentPromtCalledCount()) {
      setIsCaptchaModalOpen(true);
      return;
    }
    setIsDataSetSizeLimit(handleGetCountSaveJsonData());

    const currentTime = Date.now();
    const timeTaken = lastClickTime ? (currentTime - lastClickTime) / 1000 : 0;

    setCount(prevCount => prevCount + 1);

    let chosenValue = 0;
    // Handle saving the current data
    switch (method) {
      case "A":
        chosenValue = 0;
        break;
      case "B":
        chosenValue = 1;
        break;
      case "Both":
        chosenValue = 0.5;
        break;
      case "None":
        chosenValue = -1;
        break;
      default:
        break;
    }
    handleSaveJsonData({
      prompt: getNextData?.prompt,
      responses: [getNextData?.responses[0], getNextData?.responses[1]],
      uniqueID: getNextData?.uniqueID,
      chosen: chosenValue,
      time_taken: timeTaken,
    });

    // Update lastClickTime for the next calculation
    setLastClickTime(currentTime);

    // Fetch the next response and update the UI
    getNextRes();
  }

  return (
    <div>
      <div className='relative z-[2]'>
        {isCapsuleModalOpen &&
          <UseCapsuleModal
            isCapsuleModalOpen={isCapsuleModalOpen}
            setIsCapsuleModalOpen={setIsCapsuleModalOpen}
            capsuleClient={capsuleClient}
          />
        }
        {isShowScrollTopBtn && <ScrollTopButton />}
        <ToastContainer />
        {isLoading ?
          <div className='min-h-screen w-full flex items-center justify-center'>
            <img src={LoadingImg} alt='loading' />
          </div>
          :
          <div className='min-h-screen pb-20 pt-[82px]'>
            {/* Add the Tour component at the top level */}
            <Tour
              steps={TOUR_STEPS}
              isOpen={isTourOpen}
              onRequestClose={() => setIsTourOpen(false)}
              closeWithMask={false}
              showCloseButton={true}
              showNavigation={true}
              showNavigationNumber={false}
              disableKeyboardNavigation={false}
              rounded={8}
              accentColor="#007bff"
              lastStepNextButton={<button style={tourStyles.buttonNext}>Finish</button>}
              prevButton={<button style={tourStyles.buttonSkip}>Previous</button>}
              nextButton={<button style={tourStyles.buttonNext}>Next</button>}
            />
            {/* <div className="text-white text-center py-4 bg-red-600 bg-opacity-80 rounded-lg mx-auto px-[24px] w-fit">
              Site is still in active development. Rewards are in the process of being built and currently only reward telegram points.
            </div> */}
            <div className='max-w-[1128px] m-auto md:mt-[32px] mt-[10px] md:px-[164px] px-[16px]'>

              <div className='flex gap-[16px] items-start'>
                <img src={LogoCyanImg} alt='logo' data-tour="vana-logo" />
                <div className='p-[12px] bg-white/10 rounded-tr-[12px] rounded-br-[12px] rounded-bl-[12px] font-400-14-16 text-white/70' data-tour="prompt">
                  {getNextData?.prompt && <p>{getNextData.prompt.replace(/answer in 2 sentences\.?/i, '').trim()}</p>}
                </div>
              </div>

              <div className={`gap-[16px] mt-[20px] relative grid grid-cols-1 md:grid-cols-2`}>
                <div data-tour="answer1" className='w-full md:h-full h-fit md:min-h-[312px] p-[16px] rounded-[12px] border border-white/10 font-normal text-[16px] leading-[21px] text-white'>
                  {getNextData?.responses[0]?.response && (
                    <PromptResponseView response={getNextData.responses[0].response} />
                  )}
                </div>
                <div data-tour="answer2" className='w-full md:h-full h-fit md:min-h-[312px] p-[16px] rounded-[12px] border border-white/10 font-normal text-[16px] leading-[21px] text-white'>
                  {getNextData?.responses[1]?.response && (
                    <PromptResponseView response={getNextData.responses[1].response} />
                  )}
                </div>
              </div>
              <div data-tour="click-option">
                <PromptLabel
                  handleClickOption={handleClickOption}
                  isDataSetSizeLimit={isDataSetSizeLimit}
                />
              </div>
              <div data-tour={`${isShowScrollTopBtn ? 'submit-point' : ''}`} className={`md:hidden flex flex-col mt-[20px] bg-white/5 rounded-[12px] p-[16px] text-white min-w-[140px]`}>
                <PromptSubmitGroup
                  count={count}
                  walletAddress={walletAddress ?? undefined}
                  handleDepositPoints={uploadDataSetToDlp}
                  setIsCapsuleModalOpen={setIsCapsuleModalOpen}
                />
              </div>
              {/* <div className="md:hidden block text-white text-center bg-red-600 bg-opacity-80 rounded-lg mx-auto text-[14px] p-1 w-full">
                The Phala network is experiencing considerable load, once you submit your answers it may take up 15 minutes for TEE validation to be processed
              </div> */}
            </div>
          </div>
        }
        {progress > 0 && (
          <ProgressBar stepDescription={DLP_UPLOAD_STEP_DESCRIPTIONS} progress={progress} />
        )}
        <div data-tour={`${isShowScrollTopBtn ? '' : 'submit-point'}`} className={`hidden md:flex flex-col absolute right-[24px] top-[114px] bg-white/5 rounded-[12px] p-[16px] text-white min-w-[140px]`}>
          <PromptSubmitGroup
            count={count}
            walletAddress={walletAddress ?? undefined}
            handleDepositPoints={uploadDataSetToDlp}
            setIsCapsuleModalOpen={setIsCapsuleModalOpen}
          />
        </div>
        {/* <div className="hidden md:block absolute right-[24px] top-[250px] text-white text-center bg-red-600 bg-opacity-80 rounded-lg mx-auto text-[14px] p-1 w-[190px]">
          The Phala network is experiencing considerable load, once you submit your answers it may take up 15 minutes for TEE validation to be processed
        </div> */}
        {isCaptchaModalOpen && (
          <CaptchaModal
            setIsCaptchaModalOpen={setIsCaptchaModalOpen}
          />
        )}
      </div>

      <div className='absolute left-[-1200px] bottom-0  bg-[rgb(125, 69, 150)] w-[500px] h-[500px] z-[1]' style={{ boxShadow: '500px 500px rgb(125, 69, 150)', filter: 'blur(500px)' }} />
      <div className='absolute right-[0] top-[-1200px]  bg-[rgb(151, 255, 254)] w-[500px] h-[500px] z-[1]' style={{ boxShadow: '500px 500px rgb(151, 255, 254)', filter: 'blur(500px)' }} />
    </div>
  )
}

export default Prompt;
