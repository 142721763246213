import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './font.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Buffer } from 'buffer';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://0ca9c7c9e5c2f6fcbefd7e186d258a43@o4508692980170752.ingest.us.sentry.io/4508700327084032",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/vanatensor\.io/, /^https:\/\/vanatensor\.com/, /^https:\/\/vana-tensor-frontend-git/],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend(event) {
    // This will create a new event for each occurrence
    event.fingerprint = ['{{ default }}', Date.now().toString()];
    return event;
  },
  // Optionally, adjust the sample rate if you want to capture more events
  sampleRate: 1.0,
});

window.Buffer = Buffer;
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
